<template>
  <div>
    <v-card class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">Tervetuloa</v-card-title>
      <v-card-subtitle>Kirjaudu tilillesi</v-card-subtitle>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="email"
            :validate-on-blur="false"
            label="Sähköpostiosoite"
            name="email"
            outlined
            @keyup.enter="login"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Salasana"
            name="password"
            outlined
            @keyup.enter="login"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-btn block x-large color="primary" @click="login">Kirjaudu</v-btn>

          <div class="mt-5">
            <router-link to="/forgotpassword"> Unohdin salasanani </router-link>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions } from "vuex";
export default {
  title: "Kirjaudu sisään",

  mixins: [mixins],

  data() {
    return {
      email: "",
      password: "",
      // show password field
      showPassword: false,
    };
  },

  created() {
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.state.groupMode) {
        this.$router.push("/group/overview");
      } else {
        this.$router.push("/overview");
      }
    }
  },

  methods: {
    ...mapActions({ getToken: "getToken" }),
    ...mapActions("account", ["getCurrentUser"]),

    async login() {
      try {
        await this.getToken({
          email: this.email,
          password: this.password,
        });

        await this.getCurrentUser();

        const url = this.$store.state.groupMode ? "/group/overview" : "/overview";
        this.$router.push(url);
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
